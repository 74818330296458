import React, { useState, useEffect } from 'react'
import { enquireScreen, unenquireScreen } from 'enquire-js'

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handler = enquireScreen((b) => {
      setIsMobile(!!b)
    })
    return () => {
      unenquireScreen(handler)
    }
  }, [])

  return {
    isMobile
  }
}

export default useIsMobile
