import React, { useEffect } from 'react'
import useIsMobile from '@hooks/useIsMobile'
import Header from '@views/Home/Header'
import Banner from '@views/Home/Banner'
import Introduce from '@views/Home/Introduce'
// import Live from '@views/Home/Live'
import Videos from '@views/Home/Videos'
import Playback from '@views/Home/Playback'
import Guest from '@views/Home/Guest'
import Agenda from '@views/Home/Agenda'
// import Retrospect from '@views/Home/Retrospect'
import Support from '@views/Home/Support'
import Contactus from '@views/Home/Contactus'
import './index.scss'

function Home () {
  const { isMobile } = useIsMobile()
  const children = [
    <Header
      id="Header"
      key="Header"
      isMobile={isMobile}
    />,
    <Banner
      id="Banner"
      key="Banner"
      isMobile={isMobile}
    />,
    <Introduce
      id="Introduce"
      key="Introduce"
      isMobile={isMobile}
    />,
    <Videos
      id="Videos"
      key="Videos"
      isMobile={isMobile}
    />,
    // <Live
    //   id="Live"
    //   key="Live"
    //   isMobile={isMobile}
    // />,
    <Guest
      id="Guest"
      key="Guest"
      isMobile={isMobile}
    />,
    <Agenda
      id="Agenda"
      key="Agenda"
      isMobile={isMobile}
    />,
    <Playback
      id="Playback"
      key="Playback"
      isMobile={isMobile}
    />,
    // <Retrospect
    //   id="Retrospect"
    //   key="Retrospect"
    //   isMobile={isMobile}
    // />,
    <Support
      id="Support"
      key="Support"
      isMobile={isMobile}
    />,
    <Contactus
      id="Contactus"
      key="Contactus"
      isMobile={isMobile}
    />
  ]

  return (
    <main className="main">
      { children }
    </main>
  )
}

export default Home


