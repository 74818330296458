import React, { useRef, useState } from 'react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import CoverImg from '@assets/img/home/introduce/cover.png'
import './index.scss'
const host = process.env.NODE_ENV === 'development' ? 'http://192.168.10.125:3001/dso-static' : '/dso-static'

function Index ({ id }) {
  const videoData = {
    src: host + '/videos/introduce.mp4',
    cover: CoverImg
  }
  const [playing, setPlaying] = useState(false)
  const activeVideo = useRef()

  const play = async () => {
    const dom = activeVideo.current
    if (!dom) return
    if (dom.readyState !== 4) {
      await new Promise(resolve => {
        dom.oncanplay = resolve
      })
    }
    dom.play()
    setPlaying(true)
  }
  return (
    <OverPack
      always={ false }
      id={ id }
      className="introduce-container container"
      playScale={ 0.3 }
    >
      <QueueAnim
        className="content"
        type="bottom"
        interval={ 200 }
        duration={ 1000 }
        animConfig={{ opacity: [1, 0] }}
        animatingClassName={['animate__fade-in-up']}
      >
        <h2
          key={1}
          className="title"
          text={'DS0 2022 大会介绍'}
        >DS0 2022 大会介绍</h2>
        <h2
          key={2}
          className="en-title"
        >INTRODUCE</h2>
        <p
          key={3}
          className="description"
        >第二届 全球DevSecOps敏捷安全大会（DSO 2022）由悬镜安全主办，3S-Lab软件供应链安全实验室、ISC互联网安全大会、Linux基金会OpenChain社区、OpenSCA社区联合协办，以“共生·敏捷·进化”为主题，以“敏捷共生，守护中国软件供应链安全”为使命，立足全球视野，汇聚来自“产学研用”各界的顶尖技术专家、行业意见领袖、资深学者智囊、企业精英代表，聚焦DevSecOps敏捷安全、软件供应链安全和云原生安全三大典型应用场景下的新技术、新态势、新实践。 </p>
        <div
          key={4}
          className="media"
        >
          <video
            ref={activeVideo}
            src={videoData.src}
            poster={videoData.cover}
            controls={playing}
          />
          {
            !playing && <div
              className="mask"
              onClick={ () => play() }
            >
              <i className="play" />
            </div>
          }
        </div>
      </QueueAnim>
    </OverPack>
  )
}

export default Index
