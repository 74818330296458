import React from 'react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import './index.scss'

function Index ({ id, isMobile }) {
  const stepsAM = [
    {
      time: '9:00-9:30',
      title: '暖场视频'
    },
    {
      time: '9:30-9:35',
      title: '主持人开场'
    },
    {
      time: '9:35-9:45',
      title: '领导致辞',
      name: '陈钟',
      job: '北京大学计算机学院网络与安全实验室主任'
    },
    {
      time: '9:55-10:20',
      title: 'DevSecOps敏捷安全技术演进洞察（2022）',
      name: '子芽',
      job: 'DSO大会出品人'
    },
    {
      time: '10:20-10:40',
      title: 'OpenChain与供应链信任建设',
      name: 'Shane',
      job: 'OpenChain总经理'
    },
    {
      time: '10:40-11:00',
      title: '中国信通院软件供应链安全标准体系建设',
      name: '郭雪',
      job: '中国信息通信研究院云计算与大数据研究所开源和软件安全部副主任'
    },
    {
      time: '11:00-11:20',
      title: '高可信内生安全网络助力数字化转型',
      name: '何国锋',
      job: '中国电信研究院安全技术研究所所长'
    },
    {
      time: '11:20-11:40',
      title: '华为开源治理实践分享',
      name: '郑志强',
      job: '华为云计算技术有限公司网络安全专家'
    },
    {
      time: '11:40-12:00',
      title: '云RASP大规模实践',
      name: '谢奕智',
      job: '腾讯主机安全/容器安全负责人'
    }
  ]
  const stepsPM = [
    {
      time: '14:10-14:15',
      title: '主持人开场'
    },
    {
      time: '14:15-14:40',
      title: '软件供应链实体要素剖析与安全风险防范',
      name: '王晓萌',
      job: '中国信息安全测评中心高级专家'
    },
    {
      time: '14:40-15:00',
      title: '金融供应商开源治理实践',
      name: '唐冬',
      job: '恒生电子股份有限公司安全测试部部长'
    },
    {
      time: '15:00-15:20',
      title: '黄金时代·乘云问道之云原生',
      name: '靳亮',
      job: '博云科技副总经理、董事'
    },
    {
      time: '15:20-15:25',
      title: '线上抽奖（三等奖）'
    },
    {
      time: '15:25-15:45',
      title: '中国移动云能力中心DevSecOps之研发安全的探索与实践',
      name: '储兰芳',
      job: '中移（苏州）软件技术有限公司（中国移动云能力中心）研发管理专家'
    },
    {
      time: '15:45-15:50',
      title: '线上抽奖（二等奖）'
    },
    {
      time: '15:50-16:10',
      title: 'vivo移动终端安全工程实践',
      name: '成明江',
      job: '维沃移动通信有限公司安全工程组高级经理'
    },
    {
      time: '16:10-16:30',
      title: 'DevSecOps如何助力开源软件供应链安全',
      name: '马景贺',
      job: <span>OpenSSF中国工作组副组长<br/>极狐GitLab DevOps技术布道师</span>
    },
    {
      time: '16:30-16:50',
      title: '云原生制品安全防护最佳实践',
      name: '白黎明',
      job: '小佑科技技术总监'
    },
    {
      time: '16:50-17:00',
      title: '线上抽奖（一等奖）'
    }
  ]

  return (
    <OverPack
      always={ false }
      id={ id }
      className="agenda-container container"
      playScale={ 0.3 }
    >
      <QueueAnim
        className="content"
        type="bottom"
        interval={ 200 }
        duration={ 1000 }
        animConfig={{ opacity: [1, 0] }}
        animatingClassName={['animate__fade-in-up']}
      >
        <h2 key={1} className="title" text={'大会议程'}>大会议程</h2>
        <h2 key={2} className="en-title">GENERALASSEMBLY AGENDA</h2>
        <div key={3} className="">
          <QueueAnim
            className="steps-list am"
            type="bottom"
            interval={ 200 }
            duration={ 1000 }
            animConfig={{ opacity: [1, 0] }}
            animatingClassName={['animate__fade-in-up']}
          >
            {
              stepsAM.map((item, index) => {
                return (
                  <div
                    key={ index }
                    className="step-item"
                  >
                    <span className="step-time">{ item.time }</span>
                    <div className="step-content">
                      <h4 className="step-title">{ item.title }</h4>
                      { item.name && <p className="step-name">{ item.name }</p> }
                      { item.job && <p className="step-job">{ item.job }</p> }
                    </div>
                  </div>
                )
              })
            }
          </QueueAnim>
          <QueueAnim
            className="steps-list pm"
            type="bottom"
            interval={ 200 }
            duration={ 1000 }
            animConfig={{ opacity: [1, 0] }}
            animatingClassName={['animate__fade-in-up']}
          >
            {
              stepsPM.map((item, index) => {
                return (
                  <div
                    key={ index }
                    className="step-item"
                  >
                    <span className="step-time">{ item.time }</span>
                    <div className="step-content">
                      <h4 className="step-title">{ item.title }</h4>
                      { item.name && <p className="step-name">{ item.name }</p> }
                      { item.job && <p className="step-job">{ item.job }</p> }
                    </div>
                  </div>
                )
              })
            }
          </QueueAnim>
        </div>
      </QueueAnim>
    </OverPack>
  )
}

export default Index
