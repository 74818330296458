import React from 'react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import ThemeImg from '@assets/img/home/banner/theme.png'
import TitleImg from '@assets/img/home/banner/title.png'
import TimeImg from '@assets/img/home/banner/time.png'
import LogoImg from '@assets/img/home/banner/dso-logo.png'
import SponsorImg from '@assets/img/home/banner/sponsor.png'
import './index.scss'

function Index ({ id }) {
  return (
    <OverPack
      always={ false }
      id={ id }
      className="banner-container"
      playScale={ 0.3 }
    >
      <QueueAnim
        className="content"
        type="bottom"
        interval={ 200 }
        duration={ 1000 }
        animConfig={{ opacity: [1, 0] }}
        animatingClassName={['animate__fade-in-up']}
      >
        <h1 key={1} className="theme">
          <img src={ThemeImg} alt=""/>
        </h1>
        <h1
          className="title"
          key={2}
        >
          <img src={TitleImg} alt=""/>
        </h1>
        <h3
          key={3}
          className="time"
        >
          <img src={TimeImg} alt=""/>
        </h3>
        <div
          className="logo"
          key={4}
        >
          <img src={LogoImg} alt=""/>
        </div>
        <div
          className="sponsor"
          key={5}
        >
          <img src={SponsorImg} alt=""/>
        </div>
      </QueueAnim>
    </OverPack>
  )
}

export default Index
