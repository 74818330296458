import React, { useRef, useState } from 'react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import CoverImg from '@assets/img/home/introduce/cover.png'
import './index.scss'
const host = process.env.NODE_ENV === 'development' ? 'http://192.168.10.125:3001/dso-static' : '/dso-static'

function Index ({ id, isMobile }) {
  const list = [
    {
      // cover: require('@assets/img/home/videos/jinliang.png'),
      video: host + '/videos/live-chenzhong.mp4',
      title: 'Opening Speech',
      name: '陈钟（北京大学计算机学院网络与安全实验室主任）'
    },
    {
      // cover: require('@assets/img/home/videos/ziya.png'),
      video: host + '/videos/live-ziya.mp4',
      title: 'DevSecOps敏捷安全技术演进洞察(2022)',
      name: '子芽（DSO大会出品人）'
    },
    {
      // cover: require('@assets/img/home/videos/tanxiaosheng.png'),
      video: host + '/videos/live-shane.mp4',
      title: 'OpenChain与供应链信任建设',
      name: 'Shane（OpenChain总经理）'
    },
    {
      // cover: require('@assets/img/home/videos/zhangyi.png'),
      video: host + '/videos/live-guoxue.mp4',
      title: '中国信通院软件供应链安全标准体系建设',
      name: '郭雪（云计算与大数据研究所开源和软件安全部副主任）'
    },
    {
      // cover: require('@assets/img/home/videos/zhangyaojiang.png'),
      video: host + '/videos/live-heguofeng.mp4',
      title: '高可信内生安全网络助力数字化转型',
      name: '何国锋（中国电信研究院安全技术研究所所长）'
    },
    {
    // cover: require('@assets/img/home/videos/tanxiaosheng.png'),
      video: host + '/videos/live-zhengzhiqiang.mp4',
      title: '华为开源治理实践分享',
      name: '郑志强（华为云计算技术有限公司网络安全专家）'
    },
    {
    // cover: require('@assets/img/home/videos/tanxiaosheng.png'),
      video: host + '/videos/live-xieyizhi.mp4',
      title: '腾讯RASP实践',
      name: '谢奕智（腾讯主机安全/容器安全负责人）'
    },
    {
    // cover: require('@assets/img/home/videos/tanxiaosheng.png'),
      video: host + '/videos/live-wangxiaomeng.mp4',
      title: '软件供应链实体要素剖析与安全风险防范',
      name: '王晓萌（中国信息安全测评中心高级专家）'
    },
    {
    // cover: require('@assets/img/home/videos/tanxiaosheng.png'),
      video: host + '/videos/live-tangdong.mp4',
      title: '金融供应商开源治理实践',
      name: '唐东（恒生申子股份有限公司安全测试部部长）'
    },
    {
    // cover: require('@assets/img/home/videos/duangang.png'),
      video: host + '/videos/live-jinliang.mp4',
      title: '黄金时代·乘云问道之云原生',
      name: '靳亮（博云科技副总经理、董事）'
    },
    {
    // cover: require('@assets/img/home/videos/liling.png'),
      video: host + '/videos/live-chulanfang.mp4',
      title: '中国移动云能力中心DevSecOps研发安全探委实践',
      name: '储兰芳（中移（苏州）软件技术有限公司（中国移动云能力中心）研发管理专家）'
    },
    {
    // cover: require('@assets/img/home/videos/xiangshuming.png'),
      video: host + '/videos/live-chengmingjiang.mp4',
      title: 'vivo移动终端安全工程实践',
      name: '成明江（维沃移动通信有限公司安全工程组高级经理）'
    },

    {
    // cover: require('@assets/img/home/videos/lishaopeng.png'),
      video: host + '/videos/live-majinghe.mp4',
      title: 'DevSecOps如何助力开源软件供应链安全',
      name: '马景贺（OpenSSF中国工作组副组长\n' +
        '极狐GitLab DevOps技术布道师）'
    },
    {
    // cover: require('@assets/img/home/videos/wangyonghui.png'),
      video: host + '/videos/live-bailiming.mp4',
      title: ' 云原生制品安全防护最佳实践',
      name: '白黎明（小佑科技技术总监）'
    }
  ]
  const [active, setActive] = useState(list[0])
  const [playing, setPlaying] = useState(false)
  const activeVideo = useRef()

  const select = (item) => {
    setActive(item)
    play()
  }

  const play = async () => {
    const dom = activeVideo.current
    if (!dom) return
    if (dom.readyState !== 4) {
      await new Promise(resolve => {
        dom.oncanplay = resolve
      })
    }
    dom.play()
    setPlaying(true)
  }

  return (
    <OverPack
      always={ false }
      id={ id }
      className="playback-container container"
      playScale={ 0.3 }
    >
      <QueueAnim
        className="content"
        type="bottom"
        interval={ 200 }
        duration={ 1000 }
        animConfig={{ opacity: [1, 0] }}
        animatingClassName={['animate__fade-in-up']}
      >
        <h2 key={1} className="title" text={'直播回放'}>直播回放</h2>
        <h2 key={2} className="en-title">LIVE PLAYBACK</h2>
        <div className="media-container">
          <div key={3} className="media">
            <video
              ref={activeVideo}
              src={active.video}
              poster={CoverImg}
              controls={playing}
            />
            {
              !playing && <div
                className="mask"
                onClick={ () => play() }
              >
                <i className="play" />
              </div>
            }
          </div>
          <div className="menu-wrapper">
            <ul className="menu">
              {
                list.map((item, index) => {
                  return (
                    <li
                      className={active.video === item.video ? 'active' : ''}
                      key={ index }
                      onClick={() => select(item)}
                    >
                      <h4>{ item.title }</h4>
                      <p>{ item.name }</p>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </QueueAnim>
    </OverPack>
  )
}

export default Index
