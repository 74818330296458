import React from 'react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import './index.scss'

const images = []
for (let i = 1;i < 37;i++) {
  images.push(require(`@assets/img/home/support/${i}.png`))
}

function Index ({ id, isMobile }) {
  return (
    <OverPack
      always={ false }
      id={ id }
      className="support-container container"
      playScale={ 0.3 }
    >
      <QueueAnim
        className="content"
        type="bottom"
        interval={ 100 }
        duration={ 1000 }
        animConfig={{ opacity: [1, 0] }}
        animatingClassName={['animate__fade-in-up']}
      >
        <h2 key={1} className="title"  text={'大会支持'}>大会支持</h2>
        <h2 key={2} className="en-title">GENERALASSEMBLY SUPPORT</h2>
        <div key={3} className="item">
          <span className="label">主办方：</span>
          <div className="wrapper">
            悬镜安全
          </div>
        </div>
        <div key={4} className="item">
          <span className="label">支持单位：</span>
          <div className="wrapper">
            3S-Lab软件供应链安全实验室、Linux基金会OpenChain社区、ISC、OpenSCA社区
          </div>
        </div>
        <div key={5} className="item" style={{display: 'block', marginBottom: 0}}>
          <span className="label">合作媒体：</span>
          <QueueAnim
            className="list"
            type="bottom"
            interval={ 50 }
            duration={ 1000 }
            animConfig={{ opacity: [1, 0] }}
            animatingClassName={['animate__fade-in-up']}
          >
            {
              images.map((item, index) => {
                return (
                  <li key={ index }>
                    <img src={ item } alt=""/>
                  </li>
                )
              })
            }
          </QueueAnim>
        </div>

      </QueueAnim>
    </OverPack>
  )
}

export default Index
