import React from 'react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import './index.scss'

const images = [
  'ziya.png',
  'chenzhong.png',
  'shane.png',
  'guoxue.png',
  'heguofeng.png',
  'zhengzhiqiang.png',
  'xieyizhi.png',
  'wangxiaomeng.png',
  'tangdong.png',
  'jinliang.png',
  'chulanfang.png',
  'chengmingjiang.png',
  'majinghe.png',
  'bailiming.png'
]
const list = images.map(item => require(`@assets/img/home/guest/${item}`))

function Index ({ id, isMobile }) {
  return (
    <OverPack
      always={ false }
      id={ id }
      className="guest-container container"
      playScale={ 0.3 }
    >
      <QueueAnim
        className="content"
        type="bottom"
        interval={ 200 }
        duration={ 1000 }
        animConfig={{ opacity: [1, 0] }}
        animatingClassName={['animate__fade-in-up']}
      >
        <h2 key={1} className="title" text={'DS0 大会嘉宾'}>DS0 大会嘉宾</h2>
        <h2 key={2} className="en-title">DSO CONFERENCE GUEST</h2>
        <QueueAnim
          className="list"
          type="bottom"
          interval={ 200 }
          duration={ 1000 }
          animConfig={{ opacity: [1, 0] }}
          animatingClassName={['animate__fade-in-up']}
        >
          {
            list.map((item, index) => {
              return (
                <div
                  className="img-wrapper"
                  key={index}
                >
                  <img
                    src={item}
                    alt=""
                  />
                </div>
              )
            })
          }
        </QueueAnim>
      </QueueAnim>
    </OverPack>
  )
}

export default Index
