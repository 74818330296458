import React from 'react'
import '@style/index.scss'
import Home from '@views/Home'

function App () {
  return (
    <Home />
  )
}

export default App
