import React, {useEffect, useMemo, useRef, useState} from 'react'
import { Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import ArrowImg from '@assets/img/home/videos/arrow.png'
import 'swiper/css'
import './index.scss'

const host = process.env.NODE_ENV === 'development' ? 'http://192.168.10.125:3001/dso-static' : '/dso-static'

function Index ({ id, isMobile }) {
  const list = [
    {
      cover: require('@assets/img/home/videos/ziya.png'),
      video: host + '/videos/ziya.mp4',
      name: '子芽',
      job: '悬镜安全创始人兼CEO'
    },
    {
      cover: require('@assets/img/home/videos/wangyonghui.png'),
      video: host + '/videos/wangyonghui.mp4',
      name: '汪永辉',
      job: '平安壹钱包DevSecOps运营负责人'
    },
    {
      cover: require('@assets/img/home/videos/xiangshuming.png'),
      video: host + '/videos/xiangshuming.mp4',
      name: '项曙明',
      job: '中兴通讯开源合规&安全治理总监'
    },
    {
      cover: require('@assets/img/home/videos/jinliang.png'),
      video: host + '/videos/jinliang.mp4',
      name: '靳亮',
      job: '博云科技副总经理、董事'
    },
    {
      cover: require('@assets/img/home/videos/liling.png'),
      video: host + '/videos/liling.mp4',
      name: '李玲',
      job: '易车网安全总监'
    },
    {
      cover: require('@assets/img/home/videos/zhangyi.png'),
      video: host + '/videos/zhangyi.mp4',
      name: '张毅',
      job: '安全419创始人'
    },
    {
      cover: require('@assets/img/home/videos/zhangyaojiang.png'),
      video: host + '/videos/zhangyaojiang.mp4',
      name: '张耀疆',
      job: '安在新媒体创始人'
    },
    {
      cover: require('@assets/img/home/videos/duangang.png'),
      video: host + '/videos/duangang.mp4',
      name: '段钢',
      job: '看雪学苑创始人'
    },
    {
      cover: require('@assets/img/home/videos/lishaopeng.png'),
      video: host + '/videos/lishaopeng.mp4',
      name: '李少鹏',
      job: '数世咨询创始人兼CEO'
    },
    {
      cover: require('@assets/img/home/videos/tanxiaosheng.png'),
      video: host + '/videos/tanxiaosheng.mp4',
      name: '谭晓生',
      job: '赛博英杰创始人兼CEO'
    },
    // {
    //   cover: require('@assets/img/home/videos/朱木林.png'),
    //   video: host + '/videos/dongfangtong.mov',
    //   name: '朱木林',
    //   job: <span>东方通集团副总裁<br/>兼北京东方通软件有限公司副总经理</span>
    // }
  ]
  const [active, setActive] = useState(list[0])
  const [playing, setPlaying] = useState(false)
  const [controlledSwiper, setControlledSwiper] = useState(null)
  const [swiperStatus, setSwiperStatus] = useState({
    isBeginning: true,
    isEnd: false
  })

  const prev = () => {
    controlledSwiper.slidePrev()
    setSwiperStatus((value) => {
      return {
        isBeginning: controlledSwiper.isBeginning,
        isEnd: controlledSwiper.isEnd
      }
    })
  }

  const next = () => {
    controlledSwiper.slideNext()
    setSwiperStatus((value) => {
      return {
        isBeginning: controlledSwiper.isBeginning,
        isEnd: controlledSwiper.isEnd
      }
    })
  }

  const activeVideo = useRef()

  const select = (item) => {
    setActive(item)
    play()
  }

  const play = async () => {
    const dom = activeVideo.current
    if (!dom) return
    if (dom.readyState !== 4) {
      await new Promise(resolve => {
        dom.oncanplay = resolve
      })
    }
    dom.play()
    setPlaying(true)
  }

  return (
    <OverPack
      always={ false }
      id={ id }
      className="videos-container container"
      playScale={ 0.3 }
    >
      <QueueAnim
        className="content"
        type="bottom"
        interval={ 200 }
        duration={ 1000 }
        animConfig={{ opacity: [1, 0] }}
        animatingClassName={['animate__fade-in-up']}
      >
        <h2 key={1} className="title" text={'大咖视频'}>大咖视频</h2>
        <h2 key={2} className="en-title">BIG BANG VIDEO</h2>
        <div key={3} className="media">
          <video
            ref={activeVideo}
            src={active.video}
            poster={active.cover}
            controls={playing}
          />
          {
            !playing && <div
              className="mask"
              onClick={ () => play() }
            >
              <i className="play" />
            </div>
          }
        </div>
      </QueueAnim>

      <div className="swiper-container">
        <QueueAnim
          className="swiper-container"
          type="bottom"
          interval={ 200 }
          duration={ 1000 }
          animConfig={{ opacity: [1, 0] }}
          animatingClassName={['animate__fade-in-up']}
          component={Swiper}
          spaceBetween={isMobile ? 20 : 50}
          slidesPerView={ isMobile ? 2 : 3}
          modules={[Controller]}
          onSwiper={setControlledSwiper}
        >
          {
            list.map((item, index) => {
              return (
                <SwiperSlide key={ index }>
                  <div
                    className="slide-wrapper"
                    onClick={() => select(item)}
                  >
                    <div className="mask">
                      <i className="play" />
                    </div>
                    <img src={ item.cover } alt=""/>
                    <div className="info">
                      <h4>{ item.name }</h4>
                      <p>{ item.job }</p>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })
          }
        </QueueAnim>
        <div className="page-container">
          <i
            className="prev"
            onClick={() => prev()}
            style={{
              opacity: swiperStatus.isBeginning ? 0.3 : 1,
              cursor: swiperStatus.isBeginning ? 'not-allowed' : 'pointer'
            }}
          >
            <img src={ArrowImg} alt=""/>
          </i>
          <i
            className="next"
            onClick={() => next()}
            style={{
              opacity: swiperStatus.isEnd ? 0.3 : 1,
              cursor: swiperStatus.isEnd ? 'not-allowed' : 'pointer'
            }}
          >
            <img src={ArrowImg} alt=""/>
          </i>
        </div>
      </div>
    </OverPack>
  )
}

export default Index
